import Drift from "../assets/Drift.jpg";
import Utvikling from "../assets/Utvikling.jpg";
import TekniskHjelp from "../assets/TekniskHjelp.jpg";

import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Layout, Typography } from "antd";
const { Content } = Layout;
const { Title } = Typography;

const About = (data) => {
    const [langData, setLangData] = useState(data.langData);
    const getLanguageText = (textid) => {
        return langData[textid]
    }
    useEffect(() => {
      setLangData(data.langData)
    });

    return (
            <Content style={{ margin:0, minWidth:"100%", maxWidth:"100%", width:"100%"}}>
                <Layout className="contentPadding">
                <Space size={60} direction="vertical">
                    <div>
                        <Space size={24} direction="vertical">
                            <Row gutter={[24, 24]}>
                                <Col span={24} xs={24} sm={24} md={14} lg={16} xl={17} xxl={18}>
                                    <Title level={1}>{getLanguageText("service1Title")}</Title>
                                    <p>
                                    {getLanguageText("service1InfoLong")}
                                    </p>
                                </Col>
                                <Col span={24} xs={24} sm={15} md={10} lg={8} xl={7} xxl={6}>
                                    <img src={TekniskHjelp} alt="Bilde av RAM" style={{minWidth:"100%",maxWidth:"100%",width:"100%"}}></img>
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]}>
                                <Col span={24} xs={24} sm={24} md={14} lg={16} xl={17} xxl={18}>
                                    <Title level={1}>{getLanguageText("service2Title")}</Title>
                                    <p>
                                    {getLanguageText("service2InfoLong")}
                                    </p>
                                </Col>
                                <Col span={24} xs={24} sm={15} md={10} lg={8} xl={7} xxl={6}>
                                    <img src={Utvikling} alt="Bilde av programmering" style={{minWidth:"100%",maxWidth:"100%",width:"100%"}}></img>
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]}>
                                <Col span={24} xs={24} sm={24} md={14} lg={16} xl={17} xxl={18}>
                                    <Title level={1}>{getLanguageText("service3Title")}</Title>
                                    <p>
                                    {getLanguageText("service3InfoLong")}
                                    </p>
                                </Col>
                                <Col span={24} xs={24} sm={15} md={10} lg={8} xl={7} xxl={6}>
                                    <img src={Drift}  alt="Bilde av kabling" style={{minWidth:"100%",maxWidth:"100%",width:"100%"}}></img>
                                </Col>
                            </Row>
                        </Space>
                        </div>
                    </Space>
                </Layout>
            </Content>
    );
   
}

export default About;