import firebase from "firebase/app";
// Required for side-effects
import "firebase/analytics";
import "firebase/functions";
import "firebase/performance";

const settings = { timestampsInSnapshots: true };

const app = firebase.initializeApp({
	apiKey: "AIzaSyAx9pl-90cyux33icP3w3Y8ClZ_JMI0xtg",
	authDomain: "bergan-techologies.firebaseapp.com",
	projectId: "bergan-techologies",
	storageBucket: "bergan-techologies.appspot.com",
	messagingSenderId: "1000502163228",
	appId: "1:1000502163228:web:6cac23459143e6bd742cd9",
	measurementId: "G-2Z15RJ686G",
});

export const functions = app.functions();
export const perf = app.performance();
export default firebase;
