import StrixBitLogo from "../assets/LogoBlackStrixBit.svg";

import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, message, Input, Space, Layout, Typography  } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { functions } from "../Firebase.js";
const { Content} = Layout;
const { Title } = Typography;

const NewsletterForm = (data) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});

    const [langData, setLangData] = useState(data.langData);
    const getLanguageText = (textid) => {
        return langData[textid]
    }
    useEffect(() => {
      setLangData(data.langData)
    });
  
    useEffect(() => {
      forceUpdate({});
    }, []);
    const key = 'subscriptionInfo';
    const onFinish = (values) => {
      var addNewsletterSubscription = functions.httpsCallable('addNewsletterSubscription');
      message.loading({ content: `${getLanguageText("newsletterErrors").loading}...`, key });
      addNewsletterSubscription({ email: values.email })
        .then((result) => {
          console.log(result)
          if (result.data.success == true) {
            message.success({ content: `${getLanguageText("newsletterSignupComplete")}`, key, duration: 4 });
          } else {
            message.error({ content: `${getLanguageText("newsletterErrors")[result.data.error]}`, key, duration: 4 });
          }
        });
    };
  
    return (
      <Form form={form} name="horizontal_login" layout="horizontal" className="landingNewsletterForm" onFinish={onFinish}>
        <Space size={[24, 0]} className="landingNewsletterSpace" align="start" wrap>
        <Form.Item
          name="email"
          rules={[{ required: true, message: getLanguageText("pleaseEnterEmail")}]}
        >
          <Input className="landingNewsletterInput" size="medium" prefix={<UserOutlined className="site-form-item-icon" />} type="email" placeholder={getLanguageText("enterEmail")} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              size="medium"
              className="landingNewsletterButton"
              type="default"
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              {getLanguageText("sendUpdates")}
            </Button>
          )}
        </Form.Item>
        </Space>
      </Form>
    );
};

const Home = (data) => {
    const [langData, setLangData] = useState(data.langData);
    const getLanguageText = (textid) => {
        return langData[textid]
    }
    useEffect(() => {
      setLangData(data.langData)
    });

    return (
            <Content style={{ margin:0, minWidth:"100%", maxWidth:"100%", width:"100%"}}>
                <div className="landingTop">
                    <Title className="landingCompanyName whiteSelection" level={1}>BERGAN TECHNOLOGIES</Title>
                    <Title className="landingCompanySlogan whiteSelection" level={2}>{getLanguageText("companySlogan")}</Title>
                    <NewsletterForm langData={langData} />
                </div>
                <Layout className="contentPadding">
                    <Space size={60} direction="vertical">
                        <div>
                            <Row gutter={[24, 24]}>
                                <Col span={24} md={12} xl={8}>
                                    <Title level={2}>{getLanguageText("service1Title")}</Title>
                                    <p>
                                    {getLanguageText("service1Info")}
                                    </p>
                                </Col>
                                <Col span={24} md={12} xl={8}>
                                    <Title level={2}>{getLanguageText("service2Title")}</Title>
                                    <p>
                                    {getLanguageText("service2Info")}
                                    </p>
                                </Col>
                                <Col span={24} md={24} xl={8}>
                                    <Title level={2}>{getLanguageText("service3Title")}</Title>
                                    <p>
                                    {getLanguageText("service3Info")}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                                    <img src={StrixBitLogo} alt="The yellow StrixBit logo" style={{minWidth:"250px",maxWidth:"250px",width:"250px", marginBottom:"17.5px"}}></img>
                                    <p>
                                    {getLanguageText("aboutStrixBit")}
                                    </p>
                                    <p>
                                    {getLanguageText("usecasesStrixBit")}
                                    </p>
                        </div>
                    </Space>
                </Layout>
            </Content>
    );
   
}

export default Home;