import StrixBitLogo from "../assets/LogoBlackStrixBit.svg";

import React, { useEffect, useState } from 'react';
import { Space, Layout, Typography } from "antd";
const { Content } = Layout;
const { Title } = Typography;

const About = (data) => {
    const [langData, setLangData] = useState(data.langData);
    const getLanguageText = (textid) => {
        return langData[textid]
    }
    useEffect(() => {
      setLangData(data.langData)
    });

    return (
            <Content style={{ margin:0, minWidth:"100%", maxWidth:"100%", width:"100%"}}>
                <Layout className="contentPadding">
                    <Space size={60} direction="vertical">
                        <div>
                                    <Title level={1}>{getLanguageText("navigation").about}</Title>
                                    <p>
                                    {getLanguageText("aboutUsInfo")}
                                    </p>
                        </div>
                        <div>
                                    <Title level={1}>{getLanguageText("ourServices")}</Title>
                                    <p>
                                    {getLanguageText("aboutUsServices")}
                                    </p>
                        </div>
                        <div>
                                    <img src={StrixBitLogo} alt="The yellow StrixBit logo" style={{minWidth:"250px",maxWidth:"250px",width:"250px", marginBottom:"17.5px"}}></img>
                                    <p>
                                    {getLanguageText("aboutStrixBit")}
                                    </p>
                                    <p>
                                    {getLanguageText("usecasesStrixBit")}
                                    </p>
                        </div>
                    </Space>
                </Layout>
            </Content>
    );
   
}

export default About;