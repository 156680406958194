// Import React functions
import React, { useEffect, useState } from 'react';
import { NavLink, Switch, Route, useLocation } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';

// Import components
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import Services from './components/services';

// Import assets
import './App.css';
import LightLogoIcon from './assets/IkonLys.svg';
import LightLogo from './assets/HovedLogoLys.svg';

// Import antd
import './App.less';
import { Button, Row, Col, Layout, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
const { Header, Footer } = Layout;
const { SubMenu } = Menu;

const languageData = {
	en: {
		langCode: 'en',
		companySlogan: 'CHANGING THE WORLD WITH TECHNOLOGY',
		pleaseEnterEmail: 'Please input your email!',
		enterEmail: 'Enter your email address',
		sendUpdates: 'Send me updates!',
		service1Title: 'Technical help',
		service1Info: "Are you struggling to get the technology working? We can help! With our expertise in computers, phones, tablets, the internet and much more, we will make sure you get the help you need. You don't need to be afraid of any dirty sales tricks; we are 100% honest.",
		service1InfoLong: (
			<div>
				<p>There is more and more technology around us. That makes it hard to know what you need. You can quickly end up in a situation where you struggle to get the technology working. Bergan Technologies can guide you through the jungle of technology so that you get what you need and don't pay more than you should. We are experts within data, phones, tablets, the internet, and more. </p>
				<b>We can help you with ...</b>
				<ul>
					<li>finding technology that fits your needs. Do you need a new PC, router or phone? We can suggest the products that best fit you. You don't need to be afraid of any dirty sales tricks; we are 100% honest.</li>
					<li>suggesting what services you should be using. Are you planning to change your internet subscription or to find a cheaper cellular plan? We make sure to give you the best advice according to your situation. Did you know many pays for better internet than they need?</li>
					<li>repairs or upgrades. If you have broken technology, we can probably help you fix it. Contact us so we can take a look and give you a price estimate. We can also help if you want to upgrade technology that already works. If your computer is too slow for your needs, then we can upgrade it for you. </li>
					<li>installation of programs, backing up images, or any other technical help. Contact us to see if we can help you. </li>
				</ul>
			</div>
		),
		service2Title: 'Development',
		service2Info: 'We can develop! We adapt and produce everything according to your needs. We primarily develop websites and servers that easily integrate with different services. Not sure if we can help? Contact us for a non-committal chat about your needs. ',
		service2InfoLong: "Now that technology is getting better than ever; it's important to get digital. Bergan Technologies can help you with the development of websites. What we develop will be produced after the customer's specifications. We work closely with the customer to make sure they are satisfied. We can also create websites that deliver digital services. Digital services can help out in several situations. Contact us for a non-committal chat about what we can do for you. ",
		service3Title: 'Operation',
		service3Info: "We operate and maintain websites and servers for you. If we have developed something for you, we can also help operate and maintain so you don't have to. If anything goes wrong we will make sure to get it fixed. Contact us if you want to find out what we can do for you.",
		service3InfoLong: 'We operate and maintain all kinds of websites and services. We make sure that the websites you got produced from us stay updated. Bug fixes and stability gets worked on regularly so you can be sure that the website works properly. Let us take care of the operation so you can relax and focus on running your business. We contact the customer when needed and fix any bugs getting reported. We can also operate existing websites. Contact us to see if we can help you. ',
		aboutStrixBit: 'StrixBit is one of our first projects and is a physical object and comes in formats such as stickers, wristbands, and more. StrixBit uses future-oriented technology to open a website. The website will allow the person who scanned the StrixBit to use a digital service. The owner can customize the service to do anything.  ',
		usecasesStrixBit: (
			<div>
				<b>Use cases</b>
				<ul>
					<li>Access control at concerts and fairs lets the user get the opportunity to upgrade or change their ticket by scanning the wristband or using the link sent upon ordering. All changes are automatically updated, so no problems occur when a guard scans the wristband to validate the ticket.</li>
					<li>Storage control for smaller storage. When items get picked up from the storage, the user will scan the StrixBit and input the number of things they took. The owner of the StrixBit will get notified when there is a low amount of items left.</li>
					<li>Tracking of loaned products. Extend the loan time or find the owner of the product by scanning the StrixBit. It can be customized to fit all needs..</li>
					<li>Find the owner of an object. If the object is lost, others can scan the StrixBit to alert the owner that the object was found.</li>
				</ul>
			</div>
		),
		ourServices: 'Our services',
		contactUsInfo: 'We want to help you! Get in touch for a non-committal chat so we together can look at what we can do for you. We encourage you to contact us via email. We will answer you as soon as possible.',
		aboutUsInfo: 'Bergan Technologies is run by Bård Bergan and his son Ole Halvor Lindtveit Bergan. The company works within IT to let Ole Halvor fully use his computer, programming, and development skills while also learning how to run a company. ',
		aboutUsServices: "We here at Bergan Technologies work to deliver good digital services to the Norwegian market for a reasonable price. We reach this goal by planning our projects well. We work closely with our customers to develop websites according to the customer's needs. We also help customers in need of technical assistance such as training, planning, or problem-solving. ",
		staff: 'Staff ',
		phone: 'Phone: ',
		address: 'Address: ',
		email: 'Email: ',
		navigation: {
			home: 'Home',
			services: 'Services',
			about: 'About us',
			contact: 'Contact us',
			menu: 'Menu',
		},
	},
	nb: {
		langCode: 'nb',
		companySlogan: 'FORANDRER VERDEN MED TEKNOLOGI',
		newsletterSignupComplete: 'Du vil nå få oppdateringer gjennom e-post',
		newsletterErrors: {
			'email-blocked': 'E-postadressen du oppga er blokkert fra å motta oppdateringer',
			'already-subscribed': 'E-postadressen du oppga mottar allerede oppdateringer',
			'not-valid': 'E-postadressen du oppga er ikke gyldig',
			loading: 'Jobber',
		},
		pleaseEnterEmail: 'Skriv inn e-postadressen din!',
		enterEmail: 'Skriv inn e-postadressen din',
		sendUpdates: 'Send meg oppdateringer!',
		service1Title: 'Teknisk hjelp',
		service1Info: 'Sliter du med å få teknologien til å funke?  Vi kan hjelpe deg! Med vår ekspertise innen data, mobil, nettbrett, internett og mye mer vil vi sørge for at du får hjelpen du trenger. Fra oss trenger du ikke være redd for skittene salgstriks, vi er 100% ærlige.',
		service1InfoLong: (
			<div>
				<p>Det blir mer og mer teknologi rundt oss. Det gjør at det kan bli vanskelig å vite hva man trenger. Man kan enkelt havne i en situasjon hvor man sliter med å få teknologien til å funke. Bergan Technologies kan guide deg gjennom jungelen av teknologi slik at du får det du trenger og ikke betaler mer en du behøver. Vi har ekspertise innen data, mobil, nettbrett, internett og mye mer.</p>
				<b>Vi kan hjelpe deg med ...</b>
				<ul>
					<li>å finne teknologi tilpasset ditt behov. Trenger du en ny PC, ruter eller telefon? Vi kan foreslå produktene som passer best for deg.</li>
					<li>å gi anbefalinger for hvilke tjenester du bør benytte. Skal du ha nytt internett eller ønsker du et billigere mobil abonnement? Vi sørger for å gi deg den beste anbefalingen basert på din situasjon. Viste du at mange betaler for mye bedre internett enn de trenger?</li>
					<li>reparasjon eller oppgradering av teknologi. Om du har ødelagt teknologi kan vi mest sannsynlig få fikset feilen. Kontakt oss så kan vi ta en titt og gi deg et pristilbud. Vi kan også hjelpe dersom du ønsker å oppgradere teknologi som allerede funker. Om datamaskinen din har blitt treg så kan vi oppgradere den.</li>
					<li>installasjon av programmer, lagring av bilder eller annen teknisk hjelp. Kontakt oss for å finne ut om vi kan hjelpe deg!</li>
				</ul>
			</div>
		),
		service2Title: 'Utvikling',
		service2Info: 'Vi kan utvikling! Vi tilpasser og produserer alt etter behov. Vi utvikler hovedsakelig nettsider og servere som sørger for enkel integrasjon med forskjellige tjenester. Ikke helt sikkert på om vi kan hjelpe deg? Ta kontakt for en uforpliktende prat.',
		service2InfoLong: 'Nå som teknologien blir bedre så er det viktig å være på plass digitalt. Bergan Technologies kan hjelpe med utvikling av nettsider. Alt vi utvikler blir produsert etter kundens behov. Vi jobber tett opp mot kunden for å sørge for at de blir fornøyde. Vi kan også utvikle nettsider som leverer digitale tjenester. Digitale tjenester forenkler ofte mange situasjoner. Ta kontakt for å en uforpliktende prat om hva vi kan gjøre for deg.',
		service3Title: 'Drift',
		service3Info: 'Vi drifter og vedlikeholder nettsider og servere for deg. Har vi utviklet noe for deg så kan vi drifte og vedlikeholde slik at du slipper. Skulle noe gå galt så vil vi sørge for å få det fikset. Om du ønsker å se hva vi kan gjøre for deg så ta kontakt.',
		service3InfoLong: 'Vi drifter og vedlikeholder alt av nettsider og tjenester. Vi sørger for at nettsidene du har fått utviklet av oss holder seg oppdatert. Feilretting og stabilitet jobbes med jevnlig slik at du kan være trygg på at nettsiden funger som den skal. La oss ta oss av drift slik at du kan slappe av og fokusere på driften av bedriften. Vi kontakter kunden ved behov og fikser feil så for de blir rapportert. Vi kan også drifte eksisterende nettsider. Kontakt oss for å se om vi kan hjelpe deg.',
		aboutStrixBit: 'StrixBit er en av våre første prosjekter. StrixBit er et fysisk objekt og kommer i former slik som klistremerker, brikker, armbånd og mer. StrixBit bruker fremtidsrettet teknologi for å åpne en nettside. Nettsiden vil la den som skannet bruke en digital tjeneste. Tjenesten som vises tilpasses nøye av eieren av StrixBit og kan gjøre så si hva som helst så bruksområdene er mange.',
		usecasesStrixBit: (
			<div>
				<b>Bruksområder</b>
				<ul>
					<li>Adgangskontroll på konserter og messer, brukeren kan da også få mulighet til å oppgradere eller endre billetten sin ved å skanne armbåndet eller å bruke en lenke sendt ved bestilling. Alle endringer blir automatisk oppdatert slik at det ikke blir tull når en vakt skanner armbåndet og validerer billetten.</li>
					<li>Lagerkontroll for mindre lager. Når varer hentes fra lager så skannes StrixBit og bruker skriver inn antall varer den tok. Når det er lite varer igjen så kan man bli varslet slik at man sørger for at lageret aldri blir tomt.</li>
					<li>Sporing av utlånte produkter. Forleng lånetiden eller finn eier av produktet ved å skanne StrixBiten. Kan tilpasses.</li>
					<li>Finne eieren av et objekt. Hvis objektet blir mistet så kan andre skanne StrixBiten slik at de kan varsle eier om at objektet er funnet.</li>
				</ul>
			</div>
		),
		ourServices: 'Våre tjenester',
		contactUsInfo: 'Vi ønsker gjerne å hjelpe deg! Ta kontakt for en uforpliktende prat så kan vi sammen se på hva vi kan gjøre for deg. Vi oppfordrer til å ta kontakt via e-post. Vi vil besvare deg så raskt vi kan.',
		aboutUsInfo: 'Bergan Technologies drives av Bård Bergan og hans sønn Ole Halvor Lindtveit Bergan. Selskaper holder til innen IT slik at Ole Halvor fullt kan utnytte sine data, programmering og utviklings ferdigheter mens han samtidig også lærer om det å drive bedrift.',
		aboutUsServices: 'Vi her i Bergan Technologies jobber for å levere gode digitale tjenester til det norske markedet til en god pris. Dette er noe vi oppnår ved å planlegge prosjekter godt. Vi jobber tett opp mot våre kunder slik at vi utvikler nettsider etter behov, vi hjelper også kunder som har behov for teknisk assistanse som opplæring, planlegging og problemløsning.',
		staff: 'Ansatte ',
		phone: 'Telefon: ',
		address: 'Adresse: ',
		email: 'E-postadresse: ',
		navigation: {
			home: 'Hjem',
			services: 'Tjenester',
			about: 'Om oss',
			contact: 'Kontakt oss',
			menu: 'Meny',
		},
	},
};

function NavPage() {
	const location = useLocation();
	var path = '/' + location.pathname.replace(/^\/([^\/]*).*$/, '$1');
	console.log(path);
	return path;
}

const SmallMenu = (data) => {
	const [langData, setLangData] = useState(data.langData);
	const getLanguageText = (textid) => {
		return langData[textid];
	};
	useEffect(() => {
		setLangData(data.langData);
	});

	return (
		<Menu theme="dark" mode="horizontal" defaultSelectedKeys={NavPage()} className="navMenu navMenuSmall" style={{ backgroundColor: '#2e0099' }}>
			<SubMenu key="sub1" icon={<MenuOutlined />} className="navButton" title={getLanguageText('navigation').menu}>
				<Menu.Item key="/">
					<NavLink className="navButton" exact to="/">
						{getLanguageText('navigation').home}
					</NavLink>
				</Menu.Item>
				<Menu.Item key="/services">
					<NavLink className="navButton" exact to="/services">
						{getLanguageText('navigation').services}
					</NavLink>
				</Menu.Item>
				<Menu.Item key="/about">
					<NavLink className="navButton" exact to="/about">
						{getLanguageText('navigation').about}
					</NavLink>
				</Menu.Item>
				<Menu.Item key="/contact">
					<NavLink className="navButton" exact to="/contact">
						{getLanguageText('navigation').contact}
					</NavLink>
				</Menu.Item>
			</SubMenu>
		</Menu>
	);
};

const BigMenu = (data) => {
	const [langData, setLangData] = useState(data.langData);
	const getLanguageText = (textid) => {
		return langData[textid];
	};
	useEffect(() => {
		setLangData(data.langData);
	});

	return (
		<Menu theme="dark" mode="horizontal" defaultSelectedKeys={NavPage()} className="navMenu navMenuBig" style={{ backgroundColor: '#2e0099' }}>
			<Menu.Item key="/">
				<NavLink className="navButton" exact to="/">
					{getLanguageText('navigation').home}
				</NavLink>
			</Menu.Item>
			<Menu.Item key="/services">
				<NavLink className="navButton" exact to="/services">
					{getLanguageText('navigation').services}
				</NavLink>
			</Menu.Item>
			<Menu.Item key="/about">
				<NavLink className="navButton" exact to="/about">
					{getLanguageText('navigation').about}
				</NavLink>
			</Menu.Item>
			<Menu.Item key="/contact">
				<NavLink className="navButton" exact to="/contact">
					{getLanguageText('navigation').contact}
				</NavLink>
			</Menu.Item>
		</Menu>
	);
};

// App function
function App() {
	return (
		<div className="App">
			<Main />
		</div>
	);
}

// Main webpage switcher
const Main = () => {
	const mql = window.matchMedia('(max-width: 1100px)');
	const [showSmallMenu, setBigMenu] = useState(mql.matches);
	const getDefaultLanguage = () => {
		if (localStorage.getItem('language') === null) {
			if (window.navigator.language === 'en') {
				localStorage.setItem('language', 'en');
				return languageData[localStorage.getItem('language')];
			} else {
				localStorage.setItem('language', 'nb');
				return languageData[localStorage.getItem('language')];
			}
		} else {
			return languageData[localStorage.getItem('language')];
		}
	};
	const [langData, setLangData] = useState(getDefaultLanguage);
	const getLanguageText = (textid) => {
		return langData[textid];
	};

	const changeLangNB = () => {
		localStorage.setItem('language', 'nb');
		setLangData(languageData[localStorage.getItem('language')]);
	};

	const changeLangEN = () => {
		localStorage.setItem('language', 'en');
		setLangData(languageData[localStorage.getItem('language')]);
	};

	mql.addEventListener('change', (e) => {
		const smallView = e.matches;
		setBigMenu(smallView);
	});

	return (
		<Layout>
			<MessengerCustomerChat pageId="107390561609688" appId="3005848119634643" language="NO_nb" />
			<Header>
				<Row gutter={[24, 24]} justify="space-between" wrap={false}>
					<Col>
						<NavLink className="navButton" exact to="/">
							<img src={showSmallMenu ? LightLogoIcon : LightLogo} alt="Lys versjon av bedriften sin hovedlogo" style={{ minHeight: '55px', maxHeight: '55px', height: '55px' }}></img>
						</NavLink>
					</Col>
					<Col>
						<Row wrap={false}>
							<Col>{showSmallMenu ? <SmallMenu langData={langData} /> : <BigMenu langData={langData} />}</Col>
							<Col flex="67px">
								{getLanguageText('langCode') === 'nb' ? (
									<Button type="link" className="navButton navLangButton" onClick={changeLangEN}>
										EN
									</Button>
								) : (
									<Button type="link" className="navButton navLangButton" onClick={changeLangNB}>
										NB
									</Button>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Header>
			<Switch>
				<Route exact path="/services">
					<Services langData={langData}></Services>
				</Route>
				<Route exact path="/about">
					<About langData={langData}></About>
				</Route>
				<Route exact path="/contact">
					<Contact langData={langData}></Contact>
				</Route>
				<Route path="/">
					<Home langData={langData}></Home>
				</Route>
			</Switch>
			<Footer>
				<Row gutter={[24, 24]} justify="space-between">
					<Col>
						<img alt="Lys versjon av bedriften sitt ikon" src={LightLogoIcon} style={{ minWidth: '60px', maxWidth: '60px', width: '60px', marginBottom: '24px' }}></img>
						<p style={{ margin: 0 }} className="whiteSelection">
							<b>BERGAN TECHNOLOGIES AS</b>
							<br />
							<b>Org nr: </b>827 391 522
							<br />
							<b>{getLanguageText('email')}</b>hello@bergantechnologies.com
							<br />
							<b>{getLanguageText('phone')}</b>+47 995 777 29
							<br />
							<b>{getLanguageText('address')}</b>Kyrkjevegen 135, 3803 BØ I TELEMARK, Norway
						</p>
					</Col>
					<Col></Col>
				</Row>
			</Footer>
		</Layout>
	);
};

// Exports
export default App;
export const domain = 'bergantechnologies.com';
