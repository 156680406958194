import PictureOleHalvor from '../assets/BildeOleHalvor.jpeg';
import PictureBard from '../assets/BildeBard.jpg';

import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Layout, Typography } from 'antd';
const { Content } = Layout;
const { Title } = Typography;

const About = (data) => {
    const [langData, setLangData] = useState(data.langData);
    const getLanguageText = (textid) => {
        return langData[textid];
    };
    useEffect(() => {
        setLangData(data.langData);
    });

    return (
        <Content style={{ margin: 0, minWidth: '100%', maxWidth: '100%', width: '100%' }}>
            <Layout className="contentPadding">
                <Space size={60} direction="vertical">
                    <div>
                        <Title level={1}>{getLanguageText('navigation').contact}</Title>
                        <p>
                            {getLanguageText('contactUsInfo')}
                            <br />
                            <br />
                            <b>BERGAN TECHNOLOGIES AS</b>
                            <br />
                            <b>Org nr: </b>827 391 522
                            <br />
                            <b>{getLanguageText('email')}</b>
                            <a href="mailto:hello@bergantechnologies.com">hello@bergantechnologies.com</a>
                            <br />
                            <b>{getLanguageText('phone')}</b>
                            <a href="tel:+4799577729">+47 995 77 729</a>
                            <br />
                            <b>{getLanguageText('address')}</b>Kyrkjevegen 135, 3803 BØ I TELEMARK, Norway
                        </p>
                    </div>
                    <div>
                        <Title level={1}>{getLanguageText('staff')}</Title>
                        <Row gutter={[24, 24]}>
                            <Col>
                                <img src={PictureOleHalvor} alt="Bilde av Ole Halvor" style={{ minWidth: '250px', maxWidth: '250px', width: '250px', marginBottom: '8px' }}></img>
                                <p style={{ maxWidth: 'max-content' }}>
                                    <b>Ole Halvor Lindtveit Bergan</b>
                                    <br />
                                    Teknisk sjef
                                    <br />
                                    <br />
                                    <b>{getLanguageText('email')}</b>
                                    <a href="mailto:olehalvor@bergantechnologies.com">olehalvor@bergantechnologies.com</a>
                                    <br />
                                    <b>{getLanguageText('phone')}</b>
                                    <a href="tel:+4799577729">+47 995 77 729</a>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Space>
            </Layout>
        </Content>
    );
};

export default About;
